@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.ui {
    font-family: 'Open Sans', sans-serif!important;
}

.force-open-sans {
    font-family: 'Open Sans', sans-serif!important;
}

.header-link {
    margin-top: auto!important;
    margin-bottom: auto!important;
}

.header-alert {
    z-index: 1002;
    background-color: pink!important
}

.my-header {
    height: 52px;
    width: 100%;
}

.my-mobile-header {
    border-bottom-style: solid!important;
    border-width: 1px!important;
    border-color: lightgrey!important;
    width: 100%!important;
}

.comment {
    position: absolute;
    right: 0;
    top: 15vh;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
}

.roboto {
    font-family: "Ubuntu Mono", monospace!important;
}

.blue {
    color: blue;
}

.my-p {
    margin-bottom: 20px;
}

.nowrap {
    white-space: pre;
    font-weight: 700;
}

.centered {
    margin: auto;
}
.vert-centered {
    margin-top: auto;
    margin-bottom: auto;
}

.two-column {
    -webkit-columns: 2;
            columns: 2;
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
}

@media only screen and (max-width: 900px) {
    .small-text {
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .two-column {
        grid-column-gap: 20px;
        -webkit-column-gap: 20px;
                column-gap: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .my-check {
        padding-top: 10px;
    }
}

.loader-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.big-text {
    font-size: 18px;
    margin-top: 5px;
}
.big-text-nom {
    font-size: 18px;
}

.centered-container {
    display: flex;
    justify-content: center;
}

.full-screen {
    background-color: white;
    width: 100%;
    height: 100%;
}

.jobbra {
    display: flex!important;
    justify-content: flex-end;
    margin-bottom: auto;
    margin-top: auto;
}

.pointer {
    cursor: pointer!important;
}

.centered-text {
    text-align: center;
}
.my-button {
    text-align: center;
    padding: 10px!important;
    margin-bottom: 10px!important;
}
.my-bigger-button {
    margin-bottom: 10px!important;
}
.sec-container {
    margin: 15px;
    text-align: center;
}
.float-right {
    float: right;
}
.right-text {
    text-align: right;
}
.right-left {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.next-to {
    display: flex;
    flex-direction: row;
}
.pl {
    padding-left: 10px;
}
.activeInPlaylist {
    background-color: #fbfba5;
}

.notLinkStyle, .notLinkStyle:hover {
    color: #000000de;
}

.playlist-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 45vmin;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    z-index: 100;
}

.footer-container {
    position:absolute;
    bottom:0;
    width: 100%;
    height: 52px;
    border-top-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    padding: 7px;
    background-color: white;
    left: 0;
}

.second-cont {
    box-sizing: border-box;
    min-height: 100%;
    padding: 0 0 20px;
    position: relative;
}

.container {
    padding-bottom: 60px!important;
}

.m-top {
    margin-top: 5px;
}

@media only screen and (max-width: 900px) {
    .playlist-container {
        width: 40vmax;
    }
}

.my-item {
    padding-top: 3px!important;
    padding-bottom: 3px!important;
}

.hover-grey:hover {
    background-color: #ebebeb;
}

.my-bottom-border {
    border-bottom-style: solid;
    border-width: 1px;
    border-color: lightgrey;
}

.left-border {
    border-left-style: solid;
}

.my-header-text {
    margin-bottom: 5px;
}

.search-bar-cont {
    display: flex;
    justify-content: space-between;
}

.bigger-icon {
    font-size: 1.4em!important;
}

.redBackground {
    background-color: rgb(253, 141, 141);
}
